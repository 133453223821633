.mainFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .mainFlex{
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.displayNoneToMobile{
    display: none;
}

@media screen and (min-width: 768px) {
    .displayNoneToMobile{
        display: block;
        flex: 40%;
    }
}

.displayYesToMobile{
    
}

@media screen and (min-width: 768px) {
    .displayYesToMobile{
        flex: 60%;
    }
}

.subFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (min-width: 768px) {
    .subFlex{
        flex-direction: row;
    }
}

.icon{
    width: 130px !important;
}
@media screen and (min-width: 768px) {
    .icon{
        width: 200px !important;
    }
}

.iconTwo{
    width: 130px !important;
}
@media screen and (min-width: 768px) {
    .iconTwo{
        width: 330px !important;
    }
}

.iconThree{
    width: 130px !important;
}
@media screen and (min-width: 768px) {
    .iconThree{
        width: 550px !important;
    }
}

.iconContainer{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
    .iconContainer{
        width: auto;
        margin-right: 8%;
        align-items: center;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.displayOnlyToMobile{
    display: block;
}

@media screen and (min-width: 768px) {
    .displayOnlyToMobile{
        display: none;
    }
}

@media screen and (min-width: 1500px) {
    .extraLargePadding{
        margin-right: 8rem;
        margin-left: 8rem;
    }
}

@media screen and (min-width: 1500px) {
    .extraLeading{
        line-height: 50px !important;
    }
}



@media screen and (min-width: 768px) {
    .line {
        height: 65px;
        /* border-left: 7px dotted grey; */
        width: 1px;
        background: repeating-linear-gradient(to bottom, transparent, transparent 8px, lightgrey 8px, lightgrey 16px);
        margin: 0 20px; /* Adjust as needed */
      }
}

@media screen and (min-width: 1500px) {
    .line {
        height: 50px;
        /* border-left: 7px dotted grey; */
        width: 1px;
        background: repeating-linear-gradient(to bottom, transparent, transparent 8px, lightgrey 8px, lightgrey 16px);
        margin: 0 20px; /* Adjust as needed */
      }
}

@media screen and (min-width: 768px) {
    .line2 {
        height: 135px;
        /* border-left: 7px dotted grey; */
        width: 1px;
        background: repeating-linear-gradient(to bottom, transparent, transparent 8px, lightgrey 8px, lightgrey 16px);
        margin: 0 20px; /* Adjust as needed */
      }
}

@media screen and (min-width: 1500px) {
    .line2 {
        height: 80px;
        /* border-left: 7px dotted grey; */
        width: 1px;
        background: repeating-linear-gradient(to bottom, transparent, transparent 8px, lightgrey 8px, lightgrey 16px);
        margin: 0 20px; /* Adjust as needed */
      }
}

.mainFlexB{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .mainFlexB{
        flex-direction: row;
        justify-content: center;
    }
}

.cardDesign{
    /* border: 1px solid #ccc ; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.innerTestimonial{
    /* border: 1px solid #ccc ; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    height: 200px;
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (min-width: 1500px) {
    .innerTestimonial{
        margin-right: 3rem;
        margin-left: 3rem;
    }
}

.testimonialImage{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.innerInnerTestimonial{
    display: flex;
    align-items: center;
}

.checkBoxBackground{
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    width: 150px;
}

@media screen and (min-width: 768px) {
    .checkBoxBackground{
        padding: 8px;
        width: 180px;
        height: 44px;
        margin-left: -4px !important;
    }
}

.checkBoxLabel{
padding-left: 10px;
color: lightgray;
font-size: 15px;
letter-spacing: .6px;
}

/* From ChatGPT */

/* Hide the default checkbox */
.checkBoxBackground input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid lightgray;
    border-radius: 3px;
    outline: none;
    margin-right: 0px;
    cursor: pointer;
}

/* Style the checkbox when it's checked */
.checkBoxBackground input[type="checkbox"]:checked {
    background-color: #9B83CB;
    border: 2px solid #9B83CB;
}

/* Create custom tick mark */
.checkBoxBackground input[type="checkbox"]:checked::after {
    content: '\2713'; /* Unicode for checkmark symbol */
    font-size: 14px;
    font-weight: 700;
    color: white; /* Yellow color for the tick */
    position: relative;
    top: -4px; /* Adjust the position of the tick */
    left: 1px; /* Adjust the position of the tick */
}

