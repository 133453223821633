.socialMediaIconsContainer{
    display: flex;
    margin: 0px 0px;
    justify-content: space-around;
}

.socialMediaIcon{
height: 22px;
margin: 0px 10px;
color: white;
}

@media screen and (min-width: 480px) {
    .socialMediaIcon{
        height: 22px;
        margin: 0px 10px;
        /* margin-top: 20px; */
        }
}

.socialMediaIcon:hover{
    background-color: #481e9c;
    /* background-color: #664F94; */
    /* height: 24px; */
}