
.mainClassAboutWebsite{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    margin: auto;
    max-width: 1460px;

}

.mainClassAboutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

}

@media screen and (min-width: 768px) {
    .mainClassAboutContainer{
        padding-left: 30px;
        padding-right: 30px;
    }
}

.aboutCoverImage{
width: 100%;
min-height: 200px;
margin-top: 10px;
object-fit: cover;
}

@media screen and (min-width: 768px) {
    .aboutCoverImage{
        margin-top: 30px;
        align-self: center;
        width: 100%;
        height: 50vh;
        object-fit: cover;
        max-width: 1460px;
        border-radius: 10px;
        }
}

.aboutImage{
        width: 100%;
        height: auto;
        margin: 20px 0px;
    }
    
    @media screen and (min-width: 480px) {
        .aboutImage{
            height: 350px;
            width: auto;
            margin: 20px 20px;
            }
    }
.aboutImagesContainer{
    padding: 20px
}

@media screen and (min-width: 480px) {
    .aboutImagesContainer{
        padding: 0px;
        }
}


.mainFlexBelowImage{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .mainFlexBelowImage{
        flex-direction: row;
        justify-content: space-between;
    }
}

.mainFlex70{
 padding-left: 10px;
 padding-right: 10px;
}

@media screen and (min-width: 768px) {
    .mainFlex70{
        flex: 70;
        padding-right: 40px;
        padding-left: 0px;
    }
}

.mainFlex30{
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (min-width: 768px) {
    .mainFlex30{
        flex: 30;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 768px) {
    .responsiveText {
      font-size: 20px !important;
      width: 280px !important;
    }
    .textHeadingOnMobile{
        font-size: 22px !important;
        padding-bottom: 6px !important;
    }
    .authorMobile{
        max-width: 190px !important;
        margin-top: 0px !important;
    }
    .authorNameMobile{
        font-size: 13px !important;
    }
  }

  .authorMobile{
    max-width: 250px !important;
}

.flexPost {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Adjust this as needed */
  }
  

  @media screen and (min-width: 768px) {
    .flexPost {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; /* Adjust this as needed */
      }
      /* Assuming you want equal spacing and your Post component can be flexible */
        .flexPost > div {
            flex: 1 1 calc(33.333% - 10px); /* Adjust the 10px to account for margins/padding */
            margin: 5px; /* Adjust as needed */
        }
    }


    .flexPostRecent {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Adjust this as needed */
    }

    .formFieldsWidthHeight {
        width: 300px;
        height: 100px;
    }

    @media screen and (min-width: 768px) {
        .formFieldsWidthHeight {
            width: 600px;
            height: 150px;
        }
    }

    .paddingLeftForForm {
        padding-left: 20px;
    }

    @media screen and (min-width: 768px) {
        .paddingLeftForForm {
            padding-left: 100px;
        }
    }

  






/* Post.css */
.post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 440px;
    background-color: #fff;
	cursor:pointer;
}

.post-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.post-content {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

.post-author {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

.post-id {
    font-size: 12px;
    color: #aaa;
    margin-bottom: 10px;
}

.post-image-link {
    font-size: 14px;
    color: #007bff;
    margin-bottom: 20px;
    text-decoration: none;
}

/* .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
} */

.imgPost {
    max-width: 100%;
    height: auto;
    border-radius: 16px;
}
  




